/**
 * @generator
 * @yields {number} Incremental numbers start from 0
 * @example
 *   const iter = counter();
 *   iter.next().value == 0;
 *   iter.next().value == 1;
 */
export default function* () {
  let value = 0;
  for (;;) yield value++;
}
