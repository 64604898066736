import componentRegistry from '../registry.js';
import DebounceEventListener from '../../helpers/event/raf-debounce-listener.js';
import counter from '../../helpers/generator/counter.js';
import map from '../../helpers/iterator/map.js';

/*
IFrame Resizer light version
Based on [iframe-resizer](https://github.com/davidjbradshaw/iframe-resizer)
- this version lib have the base/minimal features
- only use default values
- force resize the iframe when parent window is resized

To avoid memory leaks, only iframes (with a specific classname) that are attached to the document are handled
Window events listeners are shared.
If the iframe is removed from document (detached nodes) subsequent message events are ignored
*/

const IFRAME_RESIZER_PREFIX = '[iFrameSizer]';

const frameId = map(counter(), (count) => `iframeid_${count}`);
// https://github.com/davidjbradshaw/iframe-resizer/blob/da4ac20121304f7646ca0c888a4a885e0675380a/src/iframeResizer.js#L436-L469
const ignoredMessageTypes = new Set([
  'close',
  'message',
  'scrollTo',
  'scrollToOffset',
  'pageInfo',
  'pageInfoStop',
  'inPageLink',
]);

export default class IFrameResizerLight {
  #iFrameId;
  #initRetyId;
  #messageTimeoutIds = new Set();

  constructor({ el }) {
    this.el = el;

    this.#iFrameId = frameId.next().value;
    this.#start();
  }

  #resizeListener = new DebounceEventListener((event) => {
    // <prefix>resize
    // https://github.com/davidjbradshaw/iframe-resizer/blob/master/src/iframeResizer.contentWindow.js#L1076-L1084
    this.#postMessage(['resize']);
    const timeoutId = setTimeout(() => this.#restart());
    this.#messageTimeoutIds.add(timeoutId);
  });

  // connectedCallback
  /** Start communication with the content */
  #start() {
    window.addEventListener('message', this.#messageListener);
    window.addEventListener('resize', this.#resizeListener);
    this.#init();
  }

  /** Start init phase: send message and wait for content to response */
  #init() {
    // Init message
    // https://github.com/davidjbradshaw/iframe-resizer#options
    // <prefix><iframe_id>:<body_margin_v1>:<calculate_width>:<logging>:<interval>:<reserved_0>:<auto_resize>:<body_margin>:<height_calc_mode>:<body_background>:<body_padding>:<tolerance>:<in_page_links>:<resize_from>:<width_calc_mode>
    // Example (default values): [iFrameSizer]iFrameResizer:8:false:false:32::true::bodyOffset:::0:false:parent:scroll
    // https://github.com/davidjbradshaw/iframe-resizer/blob/da4ac20121304f7646ca0c888a4a885e0675380a/src/iframeResizer.contentWindow.js#L194-L216
    // https://github.com/davidjbradshaw/iframe-resizer/blob/268d3733ae3758e103383ba242d5bf6d8cdbb11a/src/iframeResizer.js#L690-L706
    // https://github.com/davidjbradshaw/iframe-resizer/blob/268d3733ae3758e103383ba242d5bf6d8cdbb11a/src/iframeResizer.js#L32-L61
    // Note: you can send send partial message: values next calculate_width are optional (but need to kept in order) and will be defaulted
    // https://github.com/davidjbradshaw/iframe-resizer/blob/da4ac20121304f7646ca0c888a4a885e0675380a/src/iframeResizer.contentWindow.js#L1070-L1074
    const initMessage = [this.#iFrameId, 8, false, false, 32, '', true, ''];
    // Send initial message
    this.#postMessage(initMessage);
    // Start retries
    this.#initRetyId = setInterval(() => this.#postMessage(initMessage), 300);
  }

  /**
   * Send message to iframe
   *
   * @param {Array} message
   */
  #postMessage(message) {
    try {
      this.el.contentWindow.postMessage(IFRAME_RESIZER_PREFIX + message.join(':'), '*');
    } catch (error) {
      // ignore origin mismatch errors (the iframe is not loaded yet = iframe content window origin is the same as its parent, the src change, etc.)
    }
  }

  #clearTimers() {
    // Stop retry
    clearInterval(this.#initRetyId);

    // Stop timeouts
    for (const id of this.#messageTimeoutIds) {
      clearTimeout(id);
    }
    this.#messageTimeoutIds.clear();
  }

  #messageListener = ({ data, origin, source }) => {
    // Test if it's not from that iframe

    const checkOrigin =
      /*TODO has some attribute that force check origin ? value of that attribute : */ this.el.hasAttribute(
        'sandbox'
      ) &&
      !['allow-top-navigation', 'allow-top-navigation-by-user-activation'].some((t) => this.el.sandbox.contains(t)); // check origin only if we don't have any token that allow top navigation
    if (
      !source ||
      (source !== this.el.contentWindow && source.parent !== this.el.contentWindow) ||
      (checkOrigin && origin !== new URL(this.el.src, document.baseURI).origin)
    ) {
      return;
    }

    const message = String(data);
    // Message doesn't come from iframeresizer lib, ignore it
    if (!message.startsWith(IFRAME_RESIZER_PREFIX)) {
      return;
    }

    // <prefix><iframe_id>:<height>:<width>:<event>
    // https://github.com/davidjbradshaw/iframe-resizer/blob/da4ac20121304f7646ca0c888a4a885e0675380a/src/iframeResizer.contentWindow.js#L993-L1000
    const [id, height, , type] = message.slice(IFRAME_RESIZER_PREFIX.length).split(':');

    if (id !== this.#iFrameId) {
      return;
    }

    // We ignore "close" type messages https://github.com/davidjbradshaw/iframe-resizer/blob/da4ac20121304f7646ca0c888a4a885e0675380a/src/iframeResizer.contentWindow.js#L513
    // But what should we do instead? Restart (move to init phase) or close the connection?
    if (ignoredMessageTypes.has(type)) return;

    // The content talk to the parent. This is a proof that content is "alive"
    this.#clearTimers(); // In init phase that stop retries. In after-init phase that stop timeouts
    this.el.style.height = `${height}px`;
  };

  // disconnectedCallback
  /** Stop (immediatly) communication with the content */
  #close() {
    this.#clearTimers();
    window.removeEventListener('message', this.#messageListener);
    window.removeEventListener('resize', this.#resizeListener);
  }

  /** Call it when something wrong with the content (ex: no response). Move back from after-init to init phase */
  #restart() {
    this.#clearTimers();
    this.#init();
  }
}

componentRegistry.define('js-iframe-resizer', IFrameResizerLight);
