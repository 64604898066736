/**
 * Postpone its execution until next animation frame. Useful for implementing behavior that should only happen after the
 * input has stopped arriving. If immediately is set to true, the execution will be on the leading instead of the
 * trailing edge of the wait interval. Useful for limiting draws relative to events that occur faster than you can keep
 * up with.
 *
 * @example
 *   window.addEventListener('resize', (event) => console.log(event, 'normal'));
 *   window.addEventListener('resize', new DebounceEventListener((event) => console.log(event, 'debounce'), 250));
 */
// See https://developer.mozilla.org/en-US/docs/Web/API/EventListener

export default class {
  #requestID = 0;
  #listener;
  #delay;
  #immediate;

  constructor(listener, immediate = false) {
    this.#listener = listener;
    this.#immediate = immediate;
  }

  #exec(listener) {
    if (this.#requestID === 0 && this.#immediate) {
      listener();
    } else {
      cancelAnimationFrame(this.#requestID);
    }

    this.#requestID = requestAnimationFrame(() => {
      this.#requestID = 0;
      if (!this.#immediate) listener();
    });
  }

  handleEvent(event) {
    this.#exec(() => this.#listener(event));
  }

  // This is a hack to support jQuery that don't handle EventListener (object with handleEvent())
  // $(input).on('input', (event) => console.log(event, 'normal'))
  //    .on('input', new DebounceEventListener((event) => console.log(event, 'debounce'), 250))
  // https://github.com/jquery/jquery/issues/1735
  // https://github.com/jquery/jquery/blob/e539bac79e666bba95bba86d690b4e609dca2286/src/event.js#L334
  // TODO remove when jQuery is no more supported or DebounceEventListener is no more used in jQuery contexts
  apply(thisArg, args) {
    this.#exec(() => this.#listener.apply(thisArg, args));
  }
}
