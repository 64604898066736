/**
 * @param {Iterable} iterable
 * @param {Function} callbackFn
 * @example
 *   [..s.map([1, 2], (v) => v * 100)]// [100, 200]; same as [1, 2].map((v) => v * 100)
 * @example
 *   [
 *     ...map(
 *       function* () {
 *         yield 1;
 *         yield 2;
 *       },
 *       (v) => v * 100
 *     ),
 *   ]; //
 */
export default function* (iterable, callbackFn) {
  for (const value of iterable) {
    yield callbackFn(value);
  }
}
